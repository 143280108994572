import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  Container,
} from '@material-ui/core'
import TitleIntroduce from '../components/TitleIntroduce'
import { ReactComponent as FamilyMart } from "../svgs/ConvenienceStorePage/familyMart.svg";
import { ReactComponent as HiLife } from "../svgs/ConvenienceStorePage/hiLife.svg";
import { ReactComponent as SevenEleven } from "../svgs/ConvenienceStorePage/sevenEleven.svg";

export default function ConvenienceStorePage() {
    const titleData = [
      {
        title: "超商代收",
        subTitle: "代碼條碼，操作簡單",
        content: [
          "選擇超商繳費，快速又方便。不受時間地點限制，超何時間地點經過路過隨時可以繳費。",
        ],
      },
    ];

    return (
     <Box>
       <TitleIntroduce titleData={titleData}/>
       <BenefitContent/>
       <ToolsIntroduce/>
     </Box>
    );
}



function BenefitContent() {
  const useStyles = makeStyles((theme) => ({
    backImg:{
      height: "100%", 
      minHeight: "330px",
      width: '100%',
    }
  }));
  const classes = useStyles();
  return (
    <Box className={classes.backImg}>
      <Container maxWidth="lg">
        <Grid
          container
          style={{ height: "100%", minHeight: "330px" }}
          alignItems="center"
        >
          <Grid item xs={12} style={{ marginBottom: "30px" }}>
            <Typography
              variant="h4"
              style={{ fontWeight: 700, margin: "30px 0px" }}
              color="primary"
            >
              迅速收到交易通知
            </Typography>
            <Box>
              <Typography variant="body1">
              交易完成後，會立即回傳交易通知。
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "30px" }}>
            <Typography
              variant="h4"
              style={{ fontWeight: 700, margin: "30px 0px" }}
              color="primary"
            >
              取得超商代碼
            </Typography>
            <Box>
              <Typography variant="body1">
              買家在取得一組超商代碼後，前往超商機台 (如: 全家門市FamiPort機台) 點選代碼繳費並輸入代碼，即可印出繳款單。
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

function ToolsIntroduce(){
  const useStyles = makeStyles((theme) => ({
    box:{
      height: '100%',
      minHeight:'330px',
      backgroundColor:'#F5F5F5'
    },
    iconBox:{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      margin:'15px 15px 0px'
    },
    icon:{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width:'64px',
      height:'64px',
      borderRadius:'100%',
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Container maxWidth="md">
        <Grid container justifyContent="center" style={{minHeight:'330px',height:'100%'}}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                fontWeight: 700,
                margin: "40px 0px 10px",
                textAlign: "center",
              }}
              color="primary"
            >
              合作超商
            </Typography>
          </Grid>
          <Grid item xs={12} container style={{margin:'10px 0px 40px'}} justifyContent='center'>
            <Grid item className={classes.iconBox}  >
              <Grid className={classes.icon}>
                <SevenEleven />
              </Grid>
            </Grid>
            <Grid item className={classes.iconBox}  >
              <Grid className={classes.icon}>
                <FamilyMart />
              </Grid>
            </Grid>
            <Grid item className={classes.iconBox} >
              <Grid className={classes.icon}>
                <HiLife />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}