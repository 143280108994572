import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  Container,
} from '@material-ui/core'

export default function TitleIntroduce({titleData}) {
  const useStyles = makeStyles((theme) => ({
    backImg:{
      height:'330px',
      width: '100%',
      backgroundImage:`url('assets/images/TitleIntroduce.png')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }
  }));
  const classes = useStyles();
  return(
    <Box className={classes.backImg}>
      <Container maxWidth='lg'>
        <Grid container style={{height:'330px'}} alignItems='center'>
          <Grid item xs={12} >
          {
            titleData.map((item)=>{
              return(
                <>
                <Typography variant='h3' style={{fontWeight:700,marginBottom:'10px'}} color='primary'>{item.title}</Typography>
                <Typography variant='h6' style={{fontWeight:700,marginBottom:'30px'}} color='primary'>{item.subTitle}</Typography>
                {
                  item.content.map((content)=>{
                    return <Typography variant='body1'>{content}</Typography>
                  })
                }
                </>
              ) 
            })
          }
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}