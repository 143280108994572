import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  makeStyles,
  Typography,
  InputLabel,
  IconButton,
  InputAdornment,
  useMediaQuery
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

import { ReactComponent as LogoIcon } from "../svgs/logo.svg";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";



export default function ForgotPasswordPage() {
    const [page, setPage]=useState(true);
    return page ? <VerifyMobile setPage={setPage} /> : <ReSetPassword />;
}

function VerifyMobile({setPage}){
    const isPhone = useMediaQuery("(max-width:500px)");
    const useStyles = makeStyles((theme) => ({
      box: {
        height: "100vh",
        backgroundColor: "white",
      },
      input: {
        height: "40px",
        padding: "0px",
        backgroundColor: "white",
      },
      labeltext: {
        color: "black",
        marginTop: "10px",
        marginBottom: "10px",
        fontSize: "14px",
        fontWeight: 400,
      },
    }));
      const classes = useStyles();
      const history = useHistory();
      const location = useLocation();
      const { handleSubmit, control } = useForm({
        defaultValues: {
          mobile: "",
          verificationCode: "",
        },
      });
      const [verifying, setVerifying] = useState(false);

    
      function _submit(data) {
        console.log(data);
        setPage(false)
      }
    
      return (
        <Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: "100vh", backgroundColor: "white" }}
          >
            <Grid container item spacing={1} xs={11} sm={6} md={5}>
              <Grid
                item
                xs={12}
                style={{
                  display: isPhone ? "none" : "flex",
                  justifyContent: "center",
                  margin: "50px 0px",
                }}
              >
                <LogoIcon style={{ width: "300px", height: "64px" }} />
              </Grid>
              <Grid
                item
                style={{ display: "flex", justifyContent: "center" }}
                xs={12}
              >
                <Typography
                  variant="h4"
                  style={{ fontWeight: 700 }}
                  color="primary"
                  gutterBottom
                >
                  忘記密碼
                </Typography>
              </Grid>
              <Grid container item>
                <InputLabel className={classes.labeltext}>電話</InputLabel>
                <Controller
                  name="mobile"
                  control={control}
                  rules={{
                    required: "電話為必填",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      placeholder="請輸入電話"
                      {...field}
                      error={error}
                      helperText={error && error.message}
                      fullWidth
                      InputProps={{
                        className: classes.input,
                        endAdornment: (
                          <Button
                            color="primary"
                            variant="contained"
                            style={{
                              height: 40,
                              width: "45%",
                              marginLeft: "0.5em",
                            }}
                            onClick={() => setVerifying((e) => !e)}
                            disabled={error}
                          >
                            電話驗證
                          </Button>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid container item>
                <InputLabel className={classes.labeltext}>驗證碼</InputLabel>
                <Controller
                  name="verificationCode"
                  control={control}
                  rules={{
                    required: "驗證碼未輸入",
                    // pattern: {
                    //   value: verifyPattern,
                    //   message: "驗證碼格式不正確！",
                    // },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      placeholder={verifying ? "驗證碼" : "請先按下手機驗證"}
                      {...field}
                      error={error}
                      helperText={error && error.message}
                      disabled={!verifying}
                      fullWidth
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid container item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit(_submit)}
                  style={{ marginTop: "100px" }}
                  fullWidth
                >
                  下一步
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
}

function ReSetPassword(){
    const isPhone = useMediaQuery("(max-width:500px)");
    const useStyles = makeStyles((theme) => ({
      box: {
        height: "100vh",
        backgroundColor: "white",
      },
      input: {
        height: "40px",
        padding: "0px",
        backgroundColor: "white",
      },
      labeltext: {
        color: "black",
        marginTop: "10px",
        marginBottom: "10px",
        fontSize: "14px",
        fontWeight: 400,
      },
    }));
      const classes = useStyles();
      const history = useHistory();
      const { handleSubmit, control , getValues} = useForm({
        defaultValues: {
            password: "",
            repassword: "", 
            },
      });
      const [showPassword, setShowPassword] = useState(false);
      const [showRePassword, setShowRePassword] = useState(false);
    
      function _submit(data) {
        console.log(data);
        history.push('./log-in');
      }
    
      return (
        <Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: "100vh", backgroundColor: "white" }}
          >
            <Grid container item spacing={1} xs={11} sm={6} md={5}>
              <Grid
                item
                xs={12}
                style={{
                  display: isPhone ? "none" : "flex",
                  justifyContent: "center",
                  margin: "50px 0px",
                }}
              >
                <LogoIcon style={{ width: "300px", height: "64px" }} />
              </Grid>
              <Grid
                item
                style={{ display: "flex", justifyContent: "center" }}
                xs={12}
              >
                <Typography
                  variant="h4"
                  style={{ fontWeight: 700 }}
                  color="primary"
                  gutterBottom
                >
                  忘記密碼
                </Typography>
              </Grid>
              <Grid container item>
                <InputLabel className={classes.labeltext}>密碼</InputLabel>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: "密碼為必填",
                    // pattern: {
                    //   value: passwordPattern,
                    //   message: "密碼格式錯誤，需包含英文以及數字！",
                    // },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      placeholder="請輸入密碼"
                      type={showPassword ? "text" : "password"}
                      {...field}
                      fullWidth
                      error={error}
                      helperText={error && error.message}
                      InputProps={{
                        className: classes.input,
                        endAdornment: (
                          <InputAdornment>
                            <IconButton
                              size="small"
                              onClick={() => setShowPassword((e) => !e)}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid container item>
                <InputLabel className={classes.labeltext}>
                  再次輸入密碼
                </InputLabel>
                <Controller
                  name="repassword"
                  control={control}
                  rules={{
                    required: "未輸入密碼",
                    validate: {
                      value: (value) =>
                        getValues("password") === value || "密碼不一致",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      placeholder="請再次輸入密碼"
                      type={showRePassword ? "text" : "password"}
                      {...field}
                      fullWidth
                      error={error}
                      helperText={error && error.message}
                      InputProps={{
                        className: classes.input,
                        endAdornment: (
                          <InputAdornment>
                            <IconButton
                              size="small"
                              onClick={() => setShowRePassword((e) => !e)}
                            >
                              {showRePassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid container item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit(_submit)}
                  style={{ marginTop: "100px" }}
                  fullWidth
                >
                  完成
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
}