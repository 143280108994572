import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  Container,
} from '@material-ui/core'
import TitleIntroduce from './../components/TitleIntroduce'

export default function ATMPage() {
    const titleData = [
      {
        title: "ATM、虛擬帳號",
        subTitle: "",
        content: [
          "• 買家24小時皆能透過臨櫃、網路ATM或是實體ATM完成付款。",
        ],
      },
    ];

    return (
     <Box>
       <TitleIntroduce titleData={titleData}/>
       <BenefitContent/>
     </Box>
    );
}



function BenefitContent() {
  const useStyles = makeStyles((theme) => ({
    backImg:{
      height: "100%", 
      minHeight: "330px",
      width: '100%',
    }
  }));
  const classes = useStyles();
  return (
    <Box className={classes.backImg}>
      <Container maxWidth="lg">
        <Grid
          container
          style={{ height: "100%", minHeight: "330px" }}
          alignItems="center"
        >
          <Grid item xs={12} style={{ marginBottom: "30px" }}>
            <Box>
              <Typography variant="body1">
                每一筆訂單提供一組特定帳號，讓你輕鬆查詢交易付款狀態。
              </Typography>
            </Box>
            <Typography
              variant="h4"
              style={{ fontWeight: 700, margin: "30px 0px" }}
              color="primary"
            >
              支援多元收款
            </Typography>
            <Box>
              <Typography variant="body1">多家合作銀行，選擇彈性</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
