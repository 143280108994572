import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

const TokenContext = createContext({
  token: null,
  writeToken: () => {},
});
export function useToken() {
  return useContext(TokenContext);
}
export function TokenProvider({ children }) {
  const [token, setToken] = useState(null);
  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);
  const writeToken = useCallback(
    (params) => {
      localStorage.setItem("token", params);
      setToken(params);
    },
    [setToken]
  );
  return (
    <TokenContext.Provider value={{ token, writeToken }}>
      {children}
    </TokenContext.Provider>
  );
}
