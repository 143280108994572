import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  Container,
} from '@material-ui/core'
import TitleIntroduce from './../components/TitleIntroduce'
import { ReactComponent as Jcb } from "../svgs/CreditCardPage/jcbCard.svg";
import { ReactComponent as Master } from "../svgs/CreditCardPage/masterCard.svg";
import { ReactComponent as Visa } from "../svgs/CreditCardPage/visaCard.svg";

export default function CreditCardPage() {
    const titleData = [
      {
        title: "信用卡",
        subTitle: "最即時的收費方式",
        content: [
          "• 買家可以在多樣支付場景中安全購物。更容易，也更方便。",
          "• 提供多間銀行信用卡一次付清，自由選擇度高。賣家亦可對信用卡進行開啟/關閉提供使用。",
        ],
      },
    ];

    return (
     <Box>
       <TitleIntroduce titleData={titleData}/>
       <BenefitContent/>
       <ToolsIntroduce/>
     </Box>
    );
}



function BenefitContent() {
  const useStyles = makeStyles((theme) => ({
    backImg:{
      height: "100%", 
      minHeight: "330px",
      width: '100%',
    }
  }));
  const classes = useStyles();
  return (
    <Box className={classes.backImg}>
      <Container maxWidth="lg">
        <Grid
          container
          style={{ height: "100%", minHeight: "330px" }}
          alignItems="center"
        >
          <Grid item xs={12} style={{ marginBottom: "30px" }}>
            <Typography
              variant="h4"
              style={{ fontWeight: 700, margin: "30px 0px" }}
              color="primary"
            >
              分期期數
            </Typography>
            <Box>
              <Typography variant="body1">
              方便你彈性管理信用卡帳單，最高可分24期。
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "30px" }}>
            <Typography
              variant="h4"
              style={{ fontWeight: 700, margin: "30px 0px" }}
              color="primary"
            >
              紅利折抵
            </Typography>
            <Box>
              <Typography variant="body1">
              於結帳時，可選擇使用信用卡紅利點數抵扣款項。
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

function ToolsIntroduce(){
  const useStyles = makeStyles((theme) => ({
    box:{
      height: '100%',
      minHeight:'330px',
      backgroundColor:'#F5F5F5'
    },
    iconBox:{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      margin:'15px 15px 0px'
    },
    icon:{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width:'64px',
      height:'64px',
      borderRadius:'100%',
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Container maxWidth="md">
        <Grid container justifyContent="center" style={{minHeight:'330px',height:'100%'}}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{
                fontWeight: 700,
                margin: "40px 0px 10px",
                textAlign: "center",
              }}
              color="primary"
            >
              卡別支援
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{textAlign:'center'}}>VISA、MasterCard、JCB</Typography>
          </Grid>
          <Grid item xs={12} container style={{margin:'10px 0px 40px'}} justifyContent='center'>
            <Grid item className={classes.iconBox}  >
              <Grid className={classes.icon}>
                <Visa />
              </Grid>
            </Grid>
            <Grid item className={classes.iconBox}  >
              <Grid className={classes.icon}>
                <Master />
              </Grid>
            </Grid>
            <Grid item className={classes.iconBox} >
              <Grid className={classes.icon}>
                <Jcb />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginBottom:'10px'}}>
            <Typography variant="body1" style={{textAlign:'center'}}>一鍵快速結帳，確保你的隱私及付款安全。</Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}