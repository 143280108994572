import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  Container,
} from '@material-ui/core'
import TitleIntroduce from './../components/TitleIntroduce'

export default function PayPage() {
    const titleData = [
      {
        title: "繳費收款",
        subTitle: "簡單自動化",
        content: [
          "利用繳費系統建立帳單，可預定日期自動發送帳單給買家。",
        ],
      },
    ];

    return (
     <Box>
       <TitleIntroduce titleData={titleData}/>
       <BenefitContent/>
     </Box>
    );
}



function BenefitContent() {
  const useStyles = makeStyles((theme) => ({
    backImg:{
      height: "100%", 
      minHeight: "330px",
      width: '100%',
    }
  }));
  const classes = useStyles();
  return (
    <Box className={classes.backImg}>
      <Container maxWidth="lg">
        <Grid
          container
          style={{ height: "100%", minHeight: "330px" }}
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ fontWeight: 700, margin: "30px 0px" }}
              color="primary"
            >
              適合你
            </Typography>
            <Box style={{ marginLeft: "15px" }}>
              <ul>
                <li>
                  <Typography variant="body1">無系統管理後台</Typography>
                </li>
                <li>
                  <Typography variant="body1">無技術開發能力</Typography>
                </li>
                <li>
                  <Typography variant="body1">週期性帳單發送需求</Typography>
                </li>
              </ul>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "30px" }}>
            <Typography
              variant="h4"
              style={{ fontWeight: 700, margin: "30px 0px" }}
              color="primary"
            >
              預定自動發送日期
            </Typography>
            <Box>
              <Typography variant="body1">
              可隨時調整帳單發送的預定日期
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "30px" }}>
            <Typography
              variant="h4"
              style={{ fontWeight: 700, margin: "30px 0px" }}
              color="primary"
            >
              無紙化收款
            </Typography>
            <Box>
              <Typography variant="body1">
              解決你繁瑣的列印成本、寄送時間，告別傳統寄送紙本帳單流程
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "30px" }}>
            <Typography
              variant="h4"
              style={{ fontWeight: 700, margin: "30px 0px" }}
              color="primary"
            >
              帳單容易管理
            </Typography>
            <Box>
              <Typography variant="body1">
              可查詢所有帳單詳情，收款狀態一目了然
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
