import React, { useState, createContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Box,
  Typography,
  useMediaQuery,
  AppBar,
  Toolbar,
  useTheme,
  ButtonBase,
  Container,
  Grid,
  IconButton,
  List,
  Divider,
  ListItem,
  SwipeableDrawer,
  Link,
} from "@material-ui/core";
// pdf
import ApiPdf from "../file/yangyangpay-api_1.pdf";
import { makeStyles } from "@material-ui/styles";
// svgs
import { ReactComponent as Logo } from "../svgs/logo.svg";
// icons
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useToken } from "./TokenProvider";
const menuList = [
  {
    label: "付款",
    href: "/pay",
  },
  {
    label: "收款",
    href: "/received-payment",
  },
  // {
  //   label: "登入/註冊",
  //   href: "/log-in",
  // },
];

export const Content = createContext();

export default function Layout({ children }) {
  const [crats, setCarts] = useState([]);

  const shoppingCart = { crats, setCarts };

  return (
    <Content.Provider value={shoppingCart}>
      <div>
        <div>
          <TopBar />
        </div>
        <div>{children}</div>
        <div>
          <FooterContent />
        </div>
      </div>
    </Content.Provider>
  );
}

function TopBar() {
  const theme = useTheme();
  const { token } = useToken();
  const isTablet = useMediaQuery("(max-width:960px)");
  const history = useHistory();
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
    menuList: {
      color: "#fff",
    },
    btnBox: {
      display: "flex",
      justifyContent: "center",
      alignItem: "center",
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        position="sticky"
        style={{
          backgroundColor: "#000000",
          boxShadow: "none",
        }}
      >
        <Toolbar
          style={{ padding: "0px", minHeight: "64px", position: "relative" }}
        >
          <Container
            maxWidth="xl"
            style={{ padding: isTablet ? "0px" : "10px", minHeight: "64" }}
          >
            {/* PC TopBar */}
            <Box
              style={{
                display: isTablet ? "none" : "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* logo */}
              <ButtonBase onClick={() => history.push("/")}>
                <Logo
                  style={{
                    width: "100%",
                    height: "64",
                    padding: "5px",
                    fill: "#fff",
                  }}
                />
              </ButtonBase>
              <Box color="#fff">
                <Grid container spacing={2}>
                  {/* list */}
                  {menuList.map((item) => {
                    return (
                      <Grid item className={classes.btnBox}>
                        <Button
                          className={classes.menuList}
                          onClick={() => history.push(item.href)}
                        >
                          {item.label}
                        </Button>
                      </Grid>
                    );
                  })}
                  {/* 這邊 */}
                  {/* {token ? (
                    <Grid item className={classes.btnBox}>
                      <Button
                        className={classes.menuList}
                        onClick={() => {
                          localStorage.removeItem("token");
                          window.location.reload();
                        }}
                      >
                        登出
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item className={classes.btnBox}>
                      <Button
                        className={classes.menuList}
                        onClick={() => history.push("/log-in")}
                      >
                        登入/註冊
                      </Button>
                    </Grid>
                  )} */}
                </Grid>
              </Box>
            </Box>

            {/* ANCHOR Mobile */}
            <Box display={isTablet ? "flex" : "none"}>
              <MobileNav />
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </div>
  );
}

function MobileNav() {
  const theme = useTheme();
  const history = useHistory();
  const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
  });
  const classes = useStyles();
  const { token } = useToken();
  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={classes.list}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* drawer 內東西 */}
      <List>
        {/* back */}
        <ListItem>
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        {menuList.map((item, index) => (
          <ListItem button key={item}>
            <Typography
              style={{ color: theme.palette.text.main, cursor: "pointer" }}
              onClick={() => history.push(item.href)}
              gutterBottom
            >
              {item.label}
            </Typography>
          </ListItem>
        ))}
        {/*這邊  */}
        {/* {token ? (
          <ListItem button>
            <Typography
              style={{ color: theme.palette.text.main, cursor: "pointer" }}
              onClick={() => {
                localStorage.removeItem("token");
                window.location.reload();
              }}
              gutterBottom
            >
              登出
            </Typography>
          </ListItem>
        ) : (
          <ListItem button>
            <Typography
              style={{ color: theme.palette.text.main, cursor: "pointer" }}
              onClick={() => history.push("/log-in")}
              gutterBottom
            >
              登入/註冊
            </Typography>
          </ListItem>
        )} */}
      </List>
    </div>
  );
  return (
    // drawer 開關
    <Box style={{ display: "flex" }}>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <MenuIcon style={{ color: "#fff" }} />
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
      <Box>
        <Logo
          style={{
            width: "140px",
            height: "64",
            cursor: "pointer",
            position: "absolute",
            left: "50%",
            top: 0,
            transform: " translate(-50%, 0%)",
            fill: "#fff",
          }}
          onClick={() => history.push("/")}
        />
      </Box>
      {/* empty grid div */}
      <div style={{ opacity: 0 }}></div>
    </Box>
  );
}

// ANCHOR Footer
function FooterContent() {
  const theme = useTheme();
  const isTablet = useMediaQuery("(max-width:960px)");
  const isPhone = useMediaQuery("(max-width:600px)");
  const history = useHistory();
  const useStyles = makeStyles(() => ({}));
  const classes = useStyles();

  const footerList = [
    {
      title: "公司",
      list: [
        {
          label: "隱私保護政策",
          href: "/privacy",
        },
        {
          label: "服務條款",
          href: "/service",
        },
        {
          label: "聯絡我們",
          href: "/contact-us",
        },
      ],
    },
    {
      title: "連結",
      list: [
        {
          label: "詐騙防範須知",
          href: "/fraud-prevention",
        },
        {
          label: "交易爭議處理機制",
          href: "/transaction",
        },
      ],
    },
    {
      title: "開發者中心",
      list: [
        {
          label: "API",
          href: ApiPdf,
        },
      ],
    },
    {
      title: "交易方式",
      list: [
        {
          label: "線上付款",
          href: "/online-payment",
        },
        {
          label: "收費方式",
          href: "/charge",
        },
        {
          label: "信用卡",
          href: "/credit-card",
        },
        {
          label: "ATM",
          href: "/atm",
        },
        {
          label: "超商代收",
          href: "/convenience-store",
        },
      ],
    },
  ];

  return (
    <div style={{ backgroundColor: "#000000", paddingTop: "2em" }}>
      <Container maxWidth="xl">
        <Box py={2} color={theme.palette.text.main}>
          <Grid container alignItems="center" spacing={2}>
            {/* logo */}
            <Grid
              item
              md={4}
              container
              justifyContent={isPhone ? "center" : ""}
            >
              <Logo
                style={{
                  width: isPhone ? "140px" : "250px",
                  cursor: "pointer",
                  padding: "10px",
                  fill: "#fff",
                }}
                onClick={() => history.push("../")}
              />
            </Grid>
            <Grid
              container
              item
              spacing={2}
              md={8}
              justifyContent="space-around"
            >
              {/* list */}
              {footerList.map((item) => {
                return (
                  <Grid item container xs={12} md={3} sm={6}>
                    <Box display="flex" flexDirection="column" color="#fff">
                      <Box mb={1}>
                        <Typography
                          variant={isPhone ? "body1" : "h5"}
                          style={{
                            fontWeight: "bold",
                            borderBottom: "1px solid #fff",
                            display: "inline-block",
                          }}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                      {item.list.map((list) => {
                        if (list.label === "API") {
                          return (
                            <Link
                              color="white"
                              underline="hover"
                              style={{ cursor: "pointer", color: "#fff" }}
                              href={list.href}
                              target="_blank"
                              component="a"
                              rel="noopener"
                            >
                              <Typography
                                variant={isPhone ? "body2" : "body1"}
                                style={{ lineHeight: "2" }}
                              >
                                {list.label}
                              </Typography>
                            </Link>
                          );
                        }
                        return (
                          <Link
                            color="white"
                            underline="hover"
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push(list.href)}
                          >
                            <Typography
                              variant={isPhone ? "body2" : "body1"}
                              style={{ lineHeight: "2" }}
                            >
                              {list.label}
                            </Typography>
                          </Link>
                        );
                      })}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            {/* copy */}
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent={isTablet ? "center" : "flex-end"}
                mt={2}
                color="#fff"
              >
                <Typography variant={isPhone ? "caption" : "body2"}>
                  Copyright Ⓒ 2021 揚盛股份有限公司
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
